import logo from "./logo.svg";
import "./App.css";
import HomePage from "./components/home-page";
import NavBar from "./components/nav-bar";
import Resume from "./components/resume";
import Footer from "./components/footer";
import { ParallaxProvider } from "react-scroll-parallax";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

function App() {
  return (
    <ParallaxProvider>
      <div className="App">
        <Router>
          <NavBar />
          <Switch>
            <Route exact path="/" component={HomePage} />
            {/* <HomePage />
            </Route> */}
            <Route path="/resume" component={Resume} />
            {/* <Resume />
            </Route> */}
          </Switch>
        </Router>
      </div>
      <Footer />
    </ParallaxProvider>
  );
}

export default App;
