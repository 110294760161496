import React from "react";
import headshotOne from "../images/karina-background-hd.jpg";
import headshotTwo from "../images/karina-headshot-1.jpg";
import resume from "../images/karina-resume.png";
import styled from "styled-components";
import { Image } from "semantic-ui-react";
import { Parallax } from "react-scroll-parallax";
import VideoCarousel from "./carousel";
import ScrollAnimation from "react-animate-on-scroll";
import { useMediaQuery } from "react-responsive";

const ImgContainer = styled.div`
  width: 100vw;
  height: 100vh;
`;

const NameText = styled.p`
  font-size: 120px;
  color: #fefefe;
  font-family: "Playfair Display", serif;
  @media (max-width: 768px) {
    font-size: 50px;
  }
`;

const ParallaxImage = () => (
  <Parallax className="custom-class" y={[-20, 20]} tagOuter="figure">
    <div
      style={{
        marginTop: "20%",
        width: "100%",
        backgroundColor: "#fefefe",
        display: "flex",
        justifyContent: "space-around",
      }}
    >
      <Image src={headshotTwo} size="big" />
      <Image src={resume} size="big" />
    </div>
  </Parallax>
);

const ParallaxCarousel = () => (
  <div>
    <Parallax className="custom-class" y={[-20, 20]} tagOuter="figure">
      <VideoCarousel />
    </Parallax>
  </div>
);

const ParallaxTitle = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" });

  return (
    <Parallax
      className="custom-class"
      y={isTabletOrMobile ? [90, 20] : [30, 20]}
      tagOuter="figure"
    >
      <NameText> Karina Segura </NameText>
      <ScrollAnimation animateIn="fadeIn" animateOut="fadeOut">
        <p style={{ color: "#fefefe", fontSize: "18px" }}>
          Actor, Model, Singer, and Diversity Advocate
        </p>
      </ScrollAnimation>
    </Parallax>
  );
};

const HomePage = () => {
  const isPhone = useMediaQuery({ query: "(max-width: 420px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const imageStyles = {
    marginTop: "-11em",
    zIndex: -1,
  };
  const imageStylesTwo = isPhone
    ? {
        zIndex: -1,
      }
    : { xIndex: -1, marginTop: "40%" };
  const mobileImageStyles = isPhone
    ? {
        zIndex: -1,
        marginTop: "-85%",
      }
    : { zIndex: -1, marginTop: "-50%" };

  return isTabletOrMobile ? (
    <>
      <div className="home-image">
        {ParallaxTitle()}
        {/* <ImgContainer>
          <Image style={mobileImageStyles} src={headshotOne} fluid />
        </ImgContainer> */}
        {/* <ImgContainer>
          <Image style={imageStylesTwo} src={headshotTwo} fluid />
        </ImgContainer> */}
      </div>
    </>
  ) : (
    <>
      <ImgContainer>
        <Image style={imageStyles} src={headshotOne} fluid />
      </ImgContainer>
      {/* {ParallaxCarousel()} */}
      {/* {ParallaxImage()} */}
      {ParallaxTitle()}
    </>
  );
};

export default HomePage;
