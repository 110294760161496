import React, { useState, useEffect } from "react";
import styled from "styled-components";
import logo from "../images/karina-logo.png";
import { Parallax } from "react-scroll-parallax";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import {
  Header,
  Icon,
  Image,
  Menu,
  Segment,
  Sidebar,
  Checkbox,
} from "semantic-ui-react";
import { slide as HamMenu } from "react-burger-menu";

const NavContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 40%;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 4em;
`;

const CenterDiv = styled.div`
  display: flex;
  justify-content: center;
  margin-top: -80px;
`;

const SideBar = () => {
  return (
    <HamMenu>
      <div
        style={{ display: "flex", marginTop: "70%", flexDirection: "column" }}
      >
        <Link id="home" className="menu-item" to="/">
          Home
        </Link>
        <Link id="about" className="menu-item" to="/resume">
          Resume
        </Link>
        <a
          id="contact"
          className="menu-item"
          href="https://www.imdb.com/name/nm4748954/"
        >
          IMDB
        </a>
        <a
          className="menu-item"
          href="https://talentmg.com/talent/karina-segura/"
        >
          Agency
        </a>
      </div>
    </HamMenu>
  );
};

const NavBar = () => {
  const [activeItem, setActiveItem] = useState("home");

  const itemStyles = {
    color: "#fefefe",
    padding: "10px",
    marginTop: "40px",
  };
  const onClick = (item) => {
    setActiveItem(item);
  };
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 769px)",
  });
  const isBigScreen = useMediaQuery({ query: "(min-width: 1224px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  const isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" });
  return isTabletOrMobile ? (
    SideBar()
  ) : (
    <Parallax className="custom-class" y={[-20, 20]} tagOuter="figure">
      <CenterDiv>
        <NavContainer>
          <Menu text>
            <Link to="/" style={itemStyles}>
              Home
            </Link>
            <Link to="/resume" style={itemStyles}>
              Resume
            </Link>
            <Image src={logo} size="small" />
            <a href="https://www.imdb.com/name/nm4748954/" style={itemStyles}>
              IMDB
            </a>
            <a
              href="https://talentmg.com/talent/karina-segura/"
              style={itemStyles}
            >
              Agency
            </a>
          </Menu>
        </NavContainer>
      </CenterDiv>
    </Parallax>
  );
};

export default NavBar;
