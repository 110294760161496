import React from "react";
import { Image } from "semantic-ui-react";
import ResumeImage from "../images/karina-resume.png";
import styled from "styled-components";
import headshotOne from "../images/karina-background-red.jpg";

const ImgContainer = styled.div`
  width: 100vw;
  height: 100vh;
  margin-top: -11em;
  @media (max-width: 768px) {
    margin-top: 0;
  }
`;

const ResumeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: -30%;
`;

const Resume = () => {
  const imageStyles = {
    zIndex: -1,
    opacity: 0.7,
  };
  return (
    <>
      <ImgContainer>
        <Image style={imageStyles} src={headshotOne} fluid />
      </ImgContainer>
      <ResumeContainer>
        <Image src={ResumeImage} size="huge" />
      </ResumeContainer>
    </>
  );
};

export default Resume;
