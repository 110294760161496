import React from "react";
import Carousel from "react-material-ui-carousel";
import { Paper } from "@material-ui/core";
import { Button } from "semantic-ui-react";
import ReactPlayer from "react-player";
import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: "0",
      width: "50%",
      height: "50%",
    },
  },
}));

const Card = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px;
  width: 75%;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const DisplayText = styled.div`
  padding-left: 90px;
  @media (max-width: 768px) {
    padding: 0px;
  }
`;

const VideoCarousel = (props) => {
  var items = [
    {
      name: "Dying For a Daughter",
      description:
        "Karina Segura as Sam's best friend/co-worker Karen. With Sam and Tom being so unsuspecting of Margaret, Karen's otherwise standard 'Genre Savvy BFF' character and Segura's serviceable performance are given a boost. - vocal.media",
      color:
        "radial-gradient(circle, rgba(2,0,36,1) 0%, rgba(230,191,85,1) 0%, rgba(189,138,0,1) 100%)",
      videoLink: "https://youtu.be/z9MnGuiTtug",
      title: "Lifetime TV Movie 2020",
    },
  ];

  return (
    <Carousel animation="slide">
      {items.map((item, i) => (
        <Item key={i} item={item} />
      ))}
    </Carousel>
  );
};

function Item(props) {
  return (
    <div
      style={{
        backgroundImage: props.item.color,
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Card>
        <ReactPlayer url={props.item.videoLink} />
        <div style={{ paddingLeft: "90px" }}>
          <p
            style={{
              marginBottom: "10%",
              marginTop: "5%",
              fontSize: "18px",
              fontFamily: "sans-serif",
              fontWeight: "200",
            }}
          >
            {props.item.title}
          </p>
          <h2>{props.item.name}</h2>
          <p>{props.item.description}</p>
        </div>
      </Card>
    </div>
  );
}

export default VideoCarousel;
