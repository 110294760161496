import React from "react";
import { SocialIcon } from "react-social-icons";

const Footer = () => {
  return (
    <div className="footer">
      <SocialIcon url="https://www.instagram.com/karinasegura" />
      <SocialIcon url="https://www.imdb.com/name/nm4748954/" />
      <SocialIcon url="https://www.linkedin.com/in/karina-segura-5116a5bb/" />
    </div>
  );
};

export default Footer;
